import axios from "axios";
import {Storage} from "@/services/Storage";
import queryStringify from "qs-stringify";

export default {
    getters: {
        validateCellNumber: () => (cellNumber) => {
            if (!cellNumber) return `N/A`;
            if (cellNumber.slice(0,2) === `26`){
                if (cellNumber.length === 12){
                    return `+${cellNumber}`
                }
                return cellNumber;
            }
            if (cellNumber.length === 10){
                return cellNumber;
            }
            return cellNumber;
        },
    },
    actions: {
        fetchExpiringCustomers: async (context , includeAnnual) => {
            let dealerID = await Storage.getDealerID();
            return axios.get(`${subscriptionApi}customer/expiringDebtors?dealerID=${dealerID}&includeAnnual=`+includeAnnual)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        fetchAllCustomers: async () => {
            let dealerID = await Storage.getDealerID();
            return axios.get(`${subscriptionApi}customer/getByDealer?dealerID=${dealerID}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        fetchDevices: (context , storeID) => {
            return axios.get(`${subscriptionApi}devices/getByStore/${storeID}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        getResetInfoByStore: (context , storeDB) => {
            return axios.get(`${subscriptionApi}devices/getResetInfoByStore/${storeDB}`, {
                params : context.getters.getGlobalDateRange
            }).then(({data}) => data).catch(err => errorHandler.tomcatError(err))
        },

        getUnpaidInvoices: (context , payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(`${subscriptionApi}Invoices/getUnpaid`, {
                params : payload
            }).then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        notifyUnpaidInvoices: (context , payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(`${subscriptionApi}Invoices/notifyUnpaid`, {
                params : payload
            }).then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        getPaidInvoices: (context , payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            return axios.get(`${subscriptionApi}Invoices/getPaid`, {
                params : payload
            }).then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        exportUnpaidInvoices: (context , payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            payload = queryStringify(payload)
            let url = laravelApi+`subscription/exportUnpaidInvoices?`+payload;
            return openUrl(url);
        },

        exportPaidInvoices: (context , payload) => {
            payload = {
                ...payload,
                ...context.getters.getGlobalDateRange
            }
            payload = queryStringify(payload)
            let url = laravelApi+`subscription/exportPaidInvoices?`+payload;
            return openUrl(url);
        },
        resendReminder : async (context , id) => {
            return axios.post(subscriptionApi+`adminCustomerPanel/notifyClient/${id}`)
                .then(()=>responses.showInfo(`Reminder Sent`))
                .catch(err => errorHandler.tomcatError(err))
        },

        processManualPayment : async (context , payload) => {
            return axios.post(subscriptionApi+`paymentCompleted/processManualPayment`,payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        getAllStore : (context , mainCustomerID) => {
            return axios.get(`${subscriptionApi}customer/getStoresByCustomer?mainCustomerID=${mainCustomerID}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        
        getSubscriptionInvoices : (context , debtorID) => {
            let obj = {
                ...context.getters.getGlobalDateRange,
                debtorID : debtorID,
            }
            return axios.get(subscriptionApi+`Invoices/getPaidByCustomer`,{ params : obj })
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        getSubscriptionInvoiceByID : (context , id) => {
            return axios.get(subscriptionApi+`Invoices/getByID`,{ params : {id} })
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        massUpdateCustomer : async (context , payload) => {
            let user = await Storage.getAdminUser();
            payload.updatedBy = user.usersname + ` ` + user.userssurname
            return axios.put(subscriptionApi+`customerUpdate/massUpdate`,payload)
                .catch(err => errorHandler.tomcatError(err))
        },

        getStoreByID : async (context , id) => {
            return axios.get(subscriptionApi+`customer/getStoreByID/${id}`)
                .then(({data})=>data)
                .catch(err => errorHandler.tomcatError(err))
        },

    }
};